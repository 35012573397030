/* eslint camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
import { Fields } from '@vuex-orm/core'
import Model from '@/store/models/Model'

export default class File extends Model {
  static entity = 'File'
  static _endpoint = 'files'

  static fields(): Fields {
    return {
      id: this.attr(null),
      content_type: this.attr(null),
      url: this.attr(null),
    }
  }
}
