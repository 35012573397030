/* eslint camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
import { Fields, Record } from '@vuex-orm/core'
import { Resident } from '@/store/models/Person'
import Reaction from '@/store/models/Reaction'
import _isNil from 'lodash/isNil'
import _get from 'lodash/get'
import _map from 'lodash/map'
import { getDisplayName } from '@/utils/user'
import Model from '@/store/models/Model'

export default class Thank extends Model {
  static entity = 'Thank'
  static _endpoint = 'thanks'

  get residentName(): string {
    return getDisplayName(_get(this, 'resident'))
  }

  get senderName(): string {
    return getDisplayName(_get(this, 'sender'))
  }

  static fields(): Fields {
    return {
      id: this.number(null),
      shake: this.number(null),
      message: this.string(null),
      accepted_at: this.attr(null),
      resident_id: this.number(null),
      resident: this.belongsTo(Resident, 'resident_id'),
      sender_id: this.number(null),
      sender: this.belongsTo(Resident, 'sender_id'),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      reactions: this.morphMany(Reaction, 'reactable_id', 'reactable_type'),
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static create({ data }): Promise<any> {
    return this.dispatch('create', { data: this.doProc(data) })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static insert({ data }): Promise<any> {
    return this.dispatch('insert', { data: this.doProc(data) })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static update({ data }): Promise<any> {
    return this.dispatch('update', { data: this.doProc(data) })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static insertOrUpdate({ data }): Promise<any> {
    return this.dispatch('insertOrUpdate', { data: this.doProc(data) })
  }

  static doProc(data: Record | Record[]): Record | Record[] {
    if (_isNil(data)) return data
    return _map(Array.isArray(data) ? data : [data], (item) => this.format(item))
  }

  static format(thank: Record): Record {
    const current = this.getters('find')(thank.id)
    if (current && current.accepted_at) {
      thank.accepted_at = current.accepted_at
    }
    thank.reactions = thank.reactions.map((reaction) => Reaction.format(reaction))
    return thank
  }
}
