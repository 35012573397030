import { ActionTree } from 'vuex'
import { HistoryState } from './types'
import { RootState } from '@/store/modules/types'

const actions: ActionTree<HistoryState, RootState> = {
  start: async ({ commit }, history: History) => {
    commit('setStart', history.length)
  },
}
export default actions
