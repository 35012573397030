import { Collection, InstanceOf, Model as VuexOrmModel, Record } from '@vuex-orm/core'
import _find from 'lodash/find'
import _flatten from 'lodash/flatten'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _isNil from 'lodash/isNil'
import http from '@/utils/http'
import moment from 'moment'

export interface FetchOptions {
  // NOTE: Axiosのparamsがanyのためanyで定義しています
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: { [key: string]: any }
  useCache: boolean
  url?: string
  urlPrefix?: string
}

export interface FetchResult {
  totalCount: number
}

export default class Model extends VuexOrmModel {
  static cachedTimestamp = 0
  static cachedTimestamps: { [id: string]: number } = {}
  static loading = false
  static _endpoint: string

  static endpoint(): string {
    return this._endpoint
  }

  static afterWhere(records: Record[]): Record[] {
    return records
  }

  static afterOrderBy(records: Record[]): Record[] {
    return records
  }

  static waitLoading(): Promise<void> {
    return new Promise((resolve) => {
      const interval = window.setInterval(() => {
        if (this.loading) return
        resolve()
        clearInterval(interval)
      }, 100)
    })
  }

  static async fetchAll(fetchOptions: Partial<FetchOptions> = {}): Promise<FetchResult> {
    const useCache = !_isNil(fetchOptions['useCache']) ? fetchOptions['useCache'] : true
    const urlPrefix = fetchOptions['urlPrefix'] || ''
    const urls = []
    if (urlPrefix) {
      urls.push(urlPrefix)
    }
    urls.push(this.endpoint())
    const url = !_isNil(fetchOptions['url']) ? fetchOptions['url'] : urls.join('/')
    if (this.loading) {
      await this.waitLoading()
    }
    if (useCache && this.cachedTimestamp > moment().add(-30, 'minute').unix()) {
      return { totalCount: this.query().count() }
    }
    this.loading = true
    // NOTE: limit,pageは固定なので後ろ
    const params = {
      ..._get(fetchOptions, 'params'),
      limit: 1000,
      page: 1,
    }
    const { data, headers } = await http.get(url, { params })
    const totalCount = +_find(headers, (_val, key) => key.toLowerCase() === 'x-total-count')
    const totalPage = Math.ceil(totalCount / params.limit)
    if (totalPage > 1) {
      const requests = []
      for (params.page++; params.page <= totalPage; params.page++) {
        requests.push(((params) => http.get(url, { params }))(params))
      }
      const responses = await Promise.all(requests)
      data.push(..._flatten(_map(responses, (response) => response.data)))
    }
    this.query()
      .all()
      .filter((item) => !/^_no_key_/.test(item.$id))
      .filter((item) => !_find(data, (val) => !_isNil(val.id) && val.id === item.$id))
      .forEach((item) => {
        this.delete(`${item.$id}`)
      })
    await this.insertOrUpdate({ data })
    this.loading = false
    this.cachedTimestamp = moment().unix()
    this.cachedTimestamps = {}
    return { totalCount }
  }

  static async fetch(id: number, fetchOptions?: Partial<FetchOptions>): Promise<FetchResult>
  // eslint-disable-next-line no-dupe-class-members
  static async fetch(fetchOptions?: Partial<FetchOptions>): Promise<FetchResult>
  // eslint-disable-next-line no-dupe-class-members
  static async fetch(id?: number | Partial<FetchOptions>, fetchOptions?: Partial<FetchOptions>): Promise<FetchResult> {
    if (typeof id !== 'number') {
      fetchOptions = { ...id }
      id = null
    }
    fetchOptions = fetchOptions || {}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let params: any
    const urls = []
    if (fetchOptions['urlPrefix']) {
      urls.push(fetchOptions['urlPrefix'])
    }
    urls.push(this.endpoint())
    if (_isNil(id)) {
      // NOTE: limit,pageをパラメーターで変更できるように前
      params = {
        limit: 1000,
        page: 1,
        ..._get(fetchOptions, 'params'),
      }
    } else {
      urls.push(id)
    }
    const url = !_isNil(fetchOptions['url']) ? fetchOptions['url'] : urls.join('/')
    const cacheKey = _isNil(id) ? (_isNil(params) ? url : `${url}_${JSON.stringify(params)}`) : id.toString()
    const useCache = !_isNil(fetchOptions['useCache']) ? fetchOptions['useCache'] : true
    if (useCache) {
      const cachedTimestamp = this.cachedTimestamps[cacheKey]
      if (cachedTimestamp && cachedTimestamp > moment().add(-5, 'minute').unix()) {
        return { totalCount: this.query().count() }
      }
    }
    const { data, headers } = await http.get(url, { params })
    const totalCount = +_find(headers, (_val, key) => key.toLowerCase() === 'x-total-count')
    await this.insertOrUpdate({ data })
    this.cachedTimestamps[cacheKey] = moment().unix()
    return { totalCount }
  }

  static truncate<M extends typeof Model>(this: M): Promise<Collection<InstanceOf<M>>> {
    this.cachedTimestamp = 0
    this.cachedTimestamps = {}
    return this.deleteAll()
  }
}
