import {
  ActivatableModel as _ActivatableModel,
  CommentableModel as _CommentableModel,
  ReactableModel as _ReactableModel,
  TimelinableModel as _TimelinableModel,
} from './types'
import Me from '@/store/models/Me'
import Tenant from '@/store/models/Tenant'
import Gift from '@/store/models/Gift'
import Tweet from '@/store/models/Tweet'
import Feeling from '@/store/models/Feeling'
import Category from '@/store/models/Category'
import GiftCategory from '@/store/models/GiftCategory'
import Timeline from '@/store/models/Timeline'
import Reaction from '@/store/models/Reaction'
import Comment from '@/store/models/Comment'
import Tag, { ResidentTag, TweetTag, UserTag } from '@/store/models/Tag'
import Take from '@/store/models/Take'
import Give from '@/store/models/Give'
import Notice from '@/store/models/Notice'
import GiftSummary from '@/store/models/GiftSummary'
import Community from '@/store/models/Community'
import Thank from '@/store/models/Thank'
import Activity from '@/store/models/Activity'
import Person, { Resident, User } from '@/store/models/Person'
import CommunityGive from '@/store/models/communities/Give'
import CommunityTake from '@/store/models/communities/Take'
import CommunityMe from '@/store/models/communities/Me'
import Attachment from '@/store/models/Attachment'
import File from '@/store/models/File'
import AwardGiveCount from '@/store/models/AwardGiveCount'
import AwardTakeCount from '@/store/models/AwardTakeCount'
import AwardReactionGiveCount from '@/store/models/AwardReactionGiveCount'
import AwardReactionTakeCount from '@/store/models/AwardReactionTakeCount'
import Department from '@/store/models/Department'
import Exchange from '@/store/models/Exchange'

export type TimelinableModel = _TimelinableModel
export type ReactableModel = _ReactableModel
export type CommentableModel = _CommentableModel
export type ActivatableModel = _ActivatableModel
export { commentableType, reactableType, timelinableType, activatableType } from './const'
// NOTE: 別のmodelを継承している場合は順番によってはdatabase.registerでの登録でエラーになる可能性がある
export default {
  Person,
  User,
  Me,
  Tenant,
  GiftCategory,
  Gift,
  Tweet,
  Feeling,
  Category,
  Timeline,
  Reaction,
  Comment,
  Tag,
  UserTag,
  TweetTag,
  Take,
  Give,
  Notice,
  GiftSummary,
  Community,
  Resident,
  ResidentTag,
  Thank,
  Activity,
  CommunityGive,
  CommunityTake,
  CommunityMe,
  Attachment,
  File,
  AwardGiveCount,
  AwardTakeCount,
  AwardReactionGiveCount,
  AwardReactionTakeCount,
  Department,
  Exchange,
}
