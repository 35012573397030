import { ActionTree } from 'vuex'
import { NoticeDialogState } from './types'
import { RootState } from '@/store/modules/types'

const actions: ActionTree<NoticeDialogState, RootState> = {
  show: async ({ commit }) => {
    commit('update', { value: true })
  },
  hide: async ({ commit }) => {
    commit('update', { value: false })
  },
}
export default actions
