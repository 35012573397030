/* eslint camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
import Model from '@/store/models/Model'
import _get from 'lodash/get'
import { User } from '@/store/models/Person'
import { Fields } from '@vuex-orm/core'
import { getDisplayName } from '@/utils/user'

export default class GiftSummary extends Model {
  static entity = 'GiftSummary'
  static _endpoint = 'gifts/summaries'

  get recipientName(): string {
    return getDisplayName(_get(this, 'recipient'))
  }

  get senderName(): string {
    return getDisplayName(_get(this, 'sender'))
  }

  static fields(): Fields {
    return {
      id: this.number(null),
      recipient_id: this.number(null),
      recipient: this.belongsTo(User, 'recipient_id'),
      sender_id: this.number(null),
      sender: this.belongsTo(User, 'sender_id'),
      count: this.number(null),
      points: this.number(null),
      last_occurred_at: this.string(null),
    }
  }
}
