/* eslint camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
import { Collection, Fields, Item, Record } from '@vuex-orm/core'
import Feeling from '@/store/models/Feeling'
import _map from 'lodash/map'
import _isNil from 'lodash/isNil'
import Reaction from '@/store/models/Reaction'
import Comment from '@/store/models/Comment'
import { User } from '@/store/models/Person'
import File from '@/store/models/File'
import Model from '@/store/models/Model'

export default class Tweet extends Model {
  static entity = 'Tweet'
  static _endpoint = 'tweets'
  id!: number
  text!: string
  user_id!: number
  user!: Item<User>
  feeling_id!: number
  feeling: Item<Feeling>
  reactions: Collection<Reaction>
  comments: Collection<Comment>
  edited!: boolean
  created_at: string
  updated_at: string
  file_ids: number[]
  files: Collection<File>
  tags: string[]

  static fields(): Fields {
    return {
      id: this.attr(null),
      text: this.attr(null),
      user_id: this.attr(null),
      user: this.belongsTo(User, 'user_id'),
      feeling_id: this.attr(null),
      feeling: this.belongsTo(Feeling, 'feeling_id'),
      reactions: this.morphMany(Reaction, 'reactable_id', 'reactable_type'),
      comments: this.morphMany(Comment, 'commentable_id', 'commentable_type'),
      edited: this.attr(false),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      file_ids: this.attr([]),
      files: this.hasManyBy(File, 'file_ids'),
      tags: this.attr(null),
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static create({ data }): Promise<any> {
    return this.dispatch('create', { data: this.doProc(data) })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static insert({ data }): Promise<any> {
    return this.dispatch('insert', { data: this.doProc(data) })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static update({ data }): Promise<any> {
    return this.dispatch('update', { data: this.doProc(data) })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static insertOrUpdate({ data }): Promise<any> {
    return this.dispatch('insertOrUpdate', { data: this.doProc(data) })
  }

  static doProc(data: Record | Record[]): Record | Record[] {
    if (_isNil(data)) return data
    return _map(Array.isArray(data) ? data : [data], (tweet) => Tweet.format(tweet))
  }

  static format(tweet: Record): Record {
    if (tweet.user.feeling) {
      tweet['feeling'] = tweet.user.feeling
      delete tweet.user.feeling
    }
    tweet.reactions = tweet.reactions.map((reaction) => Reaction.format(reaction))
    tweet.comments = tweet.comments.map((comment) => Comment.format(comment))
    return tweet
  }
}
