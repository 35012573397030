<template>
  <v-snackbar
    v-model="active"
    :bottom="y === 'bottom'"
    :color="color"
    :left="x === 'left'"
    :multi-line="mode === 'multi-line'"
    :right="x === 'right'"
    :timeout="timeout"
    :top="y === 'top'"
    :vertical="mode === 'vertical'"
    class="v-application"
    @click="dismiss"
  >
    <v-row
      align="center"
      class="ma-0"
      justify="start"
    >
      <v-icon
        v-if="!!icon"
        dark
        left
      >
        {{ icon }}
      </v-icon>
      {{ text }}
      <v-spacer />
      <v-btn
        v-if="button"
        :color="button.color"
        text
        @click="onClick"
      >
        {{ button.label }}
      </v-btn>
    </v-row>
  </v-snackbar>
</template>
<script lang="ts">
import Vue from 'vue'
import NotificationOptions from './NotificationOptions'

export default Vue.extend({
  data() {
    return {
      active: false,
      text: '',
      icon: '',
      timeout: 3000,
      dismissible: true,
      y: 'top',
      x: null,
      mode: '',
      color: '',
      action: 'close',
      button: null,
    }
  },
  watch: {
    active(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.$emit('close', this.action)
      }
    },
  },
  methods: {
    show(options?: NotificationOptions) {
      Object.keys(options || {}).forEach((prop) => {
        this[prop] = options[prop]
      })
      this.active = true
    },
    close() {
      this.action = 'close'
      this.active = false
    },
    dismiss() {
      if (this.dismissible) {
        this.close()
      }
    },
    onClick() {
      this.action = this.button.action
      this.active = false
    },
  },
})
</script>
