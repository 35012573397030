import cookies from 'js-cookie'
import storage from '@/utils/AmazonesStorage'
import _get from 'lodash/get'

export const authHeaders = ['access-token', 'client', 'uid', 'token-type']
const tokenExpires = 'expiry'
const secure = process.env.NODE_ENV === 'production'

class DeviseAuthToken {
  get isAuthenticated(): boolean {
    let isAuthenticated = true
    authHeaders.forEach((key) => {
      isAuthenticated = isAuthenticated && !!cookies.get(`user.${key}`)
    })
    return isAuthenticated
  }

  store(headers): void {
    const expires = new Date(_get(headers, tokenExpires, 0) * 1000)
    authHeaders.forEach((key) => {
      if (headers[key]) {
        cookies.set(`user.${key}`, encodeURIComponent(headers[key]), { path: '/', secure, expires })
      }
    })
  }

  pull(headers: { [key: string]: string | number | boolean }): { [key: string]: string | number | boolean } {
    authHeaders.forEach((key) => {
      headers[key] = decodeURIComponent(cookies.get(`user.${key}`))
    })
    return headers
  }

  clear(): void {
    authHeaders.forEach((key) => {
      cookies.remove(`user.${key}`, { path: '/' })
      cookies.remove(`user.${key}`, { path: '' })
      cookies.remove(`user.${key}`)
    })
  }

  logout(): void {
    this.clear()
    storage.currentUrl = null
    location.href = '/logout?' + new Date().getTime()
  }
}

export default new DeviseAuthToken()
