import _isNil from 'lodash/isNil'

export function getDisplayName(user): string {
  if (_isNil(user)) return '削除メンバー'
  const name = user['name']
  if (!_isNil(user['deleted_at'])) {
    return `${name}(利用停止中)`
  }
  if (!_isNil(user['invitation_sent_at']) && _isNil(user['invitation_accepted_at'])) {
    return `${name}(招待中)`
  }
  return name
}
