/* eslint camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
import Model from '@/store/models/Model'
import { Fields } from '@vuex-orm/core'

export default class CommunityTake extends Model {
  static entity = 'CommunityTake'
  static _endpoint = 'communities/takes'

  static endpoint(): string {
    return 'takes'
  }

  static fields(): Fields {
    return {
      id: this.number(null),
      name: this.attr(null),
      email: this.string(null),
      sum: this.number(null),
      count: this.number(null),
    }
  }
}
