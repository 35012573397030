/* eslint camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
/* eslint @typescript-eslint/no-use-before-define: "off" */
import Model from '@/store/models/Model'
import { Fields } from '@vuex-orm/core'

export default class Tag extends Model {
  static entity = 'Tag'
  static _endpoint = 'tags'
  id!: number
  name!: string
  taggings_count!: number
  created_at: string
  updated_at: string

  static types(): {
    UserTag: typeof UserTag
    ResidentTag: typeof ResidentTag
    TweetTag: typeof TweetTag
  } {
    return {
      UserTag,
      ResidentTag,
      TweetTag,
    }
  }

  static fields(): Fields {
    return {
      id: this.attr(null),
      name: this.attr(null),
      taggings_count: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
    }
  }
}

export class UserTag extends Tag {
  static entity = 'UserTag'
  static _endpoint = 'users/tags'
  static baseEntity = Tag.entity

  static fields(): Fields {
    return {
      ...super.fields(),
    }
  }
}

export class ResidentTag extends Tag {
  static entity = 'ResidentTag'
  static _endpoint = 'residents/tags'
  static baseEntity = Tag.entity

  static fields(): Fields {
    return {
      ...super.fields(),
    }
  }
}

export class TweetTag extends Tag {
  static entity = 'TweetTag'
  static _endpoint = 'tweets/tags'
  static baseEntity = Tag.entity

  static fields(): Fields {
    return {
      ...super.fields(),
    }
  }
}
