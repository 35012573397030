/* eslint camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
import Model from '@/store/models/Model'
import { Fields } from '@vuex-orm/core'
import { User } from '@/store/models/Person'
import Department from '@/store/models/Department'

export default class Give extends Model {
  static entity = 'Give'
  static _endpoint = 'gives'

  static fields(): Fields {
    return {
      id: this.number(null),
      name: this.attr(null),
      email: this.string(null),
      sum: this.number(null),
      count: this.number(null),
      user: this.hasOne(User, 'id'),
      department_id: this.number(null),
      department: this.belongsTo(Department, 'department_id'),
      employee_id: this.attr(null),
    }
  }
}
