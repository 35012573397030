import { MutationTree } from 'vuex'
import { PreviewState } from './types'

const mutations: MutationTree<PreviewState> = {
  update: (state: PreviewState, payload: PreviewState) => {
    Object.keys(payload).forEach((key) => (state[key] = payload[key]))
  },
  delete: (state: PreviewState) => {
    state.delete()
  },
}
export default mutations
