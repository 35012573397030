import { PluginObject } from 'vue'
import { Configuration } from './interfaces'
import { marked } from 'marked'
import _get from 'lodash/get'
import _isNil from 'lodash/isNil'
import _some from 'lodash/some'
import _cloneDeep from 'lodash/cloneDeep'
import emoji from 'node-emoji'
import hljs from 'highlight.js'
import 'github-markdown-css/github-markdown-light.css'
import 'highlight.js/styles/github.css'
import './styles.scss'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const _emojify = (tokens): any => {
  const cloneTokens = _cloneDeep(tokens)
  for (let i = 0; i < tokens.length; i++) {
    switch (cloneTokens[i].type) {
      case 'code':
      case 'codespan':
        break
      case 'table': {
        cloneTokens[i].rows = cloneTokens[i].rows.map((cells) =>
          cells.map((cell) => {
            cell.tokens = _emojify(cell.tokens)
            return cell
          }),
        )
        cloneTokens[i].header = cloneTokens[i].header.map((header) => {
          header.tokens = _emojify(header.tokens)
          return header
        })
        break
      }
      case 'list': {
        cloneTokens[i].items = cloneTokens[i].items.map((item) => {
          item.tokens = _emojify(item.tokens)
          return item
        })
        break
      }
      default: {
        if (cloneTokens[i].tokens) {
          cloneTokens[i].tokens = _emojify(cloneTokens[i].tokens)
          break
        }
        cloneTokens[i].text = emoji.emojify(cloneTokens[i].text)
        break
      }
    }
  }
  return cloneTokens
}
const _marked = (value): string => {
  if (_isNil(value)) return ''
  const tokens = _emojify(marked.lexer(value))
  return `<div class="markdown-body">${marked.parser(tokens)}</div>`
}
const renderer = (() => {
  const renderer = new marked.Renderer()
  const link = renderer.link
  renderer.link = (href, title, text) => {
    const html = link.call(renderer, href, title, text)
    const regEx = new RegExp(location.host.replace(/[-/\\^$.*+?()[\]{}|]/g, '\\$&'))
    if (regEx.test(href)) return html
    return html.replace(/^<a /, '<a target="_blank" rel="external nofollow noopener" ')
  }
  const image = renderer.image
  renderer.image = (href, title, text) => {
    const html = image.call(renderer, href, title, text)
    return html.replace(/^<img /, '<img referrerpolicy="no-referrer" ')
  }
  return renderer
})()
const VueMarked: PluginObject<Configuration> = {
  install(Vue, options?: Configuration) {
    marked.setOptions({
      gfm: true,
      ..._get(options, 'markedOptions', {}),
      sanitize: true,
      langPrefix: '',
      highlight: (code, lang) => {
        return hljs.highlightAuto(code, [lang]).value
      },
      renderer,
    })
    Vue.filter('marked', _marked)
    Vue.prototype.$marked = _marked
    document.addEventListener(
      'click',
      (event) => {
        const elements = document.querySelectorAll('.markdown-body a[target=_blank]')
        if (_some(elements, (element) => element === event.target)) {
          event.stopImmediatePropagation()
          return
        }
      },
      true,
    )
  },
  marked(value: string) {
    return _marked(value)
  },
}
export default VueMarked
