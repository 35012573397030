import { LocaleMessages } from 'vue-i18n'
import validations from '@/plugins/i18n/validations'

const messages: LocaleMessages = {
  ja: {
    validations: {
      ...validations['ja'],
    },
    ...validations['ja'].attributes,
    label: {
      user: 'メンバー',
      action: 'アクション',
      category: '感謝タグ',
      creditCard: 'クレジットカード',
      currentPlan: '現在のプラン',
      results: '結果',
      thanksTags: '感謝タグ',
      billing: '支払い方法',
      agreements: '契約内容',
      settings: '設定',
      generalSettings: '基本設定',
      dashboard: 'ダッシュボード',
      correlationDiagram: '相関図',
      communities: 'コミュニティー',
      notice: 'お知らせ',
      integrations: 'システム連携',
      departments: '部署',
      member: 'メンバー',
      invitation: '招待',
      list: '一覧',
      listOfPoints: 'チップ一覧',
      delete: '削除',
      deleted: '削除済',
    },
    message: {
      gaveThanks: '感謝を贈りました。',
      invited: '招待しました。',
      saved: '保存しました。',
      created: '登録しました。',
      registered: '作成しました。',
      updated: '更新しました。',
      deleted: '削除しました。',
      restored: '復元しました。',
      suspended: '利用停止しました',
      posted: '投稿しました！',
      copied: 'クリップボードにコピーしました。',
      plan: {
        upgraded: 'プランが変更されました。次回ご請求より請求額が変更されます。',
      },
      user: {
        overflow: 'メンバーが上限に達しました。プランの変更をおすすめします。',
      },
      capacity: {
        notEnough: 'ご利用人数が足りていません。',
      },
      failed: '処理できませんでした。サポートにお問い合わせください。',
      unauthorized: 'ログインが必要です。',
      shook: 'シェイクしました。',
      noData: '{name}はありません。',
      noNotice: 'お知らせはありません。',
      fileSizeError: 'ファイルサイズは、{megaByte}MB以下にしてください。',
      approved: '承認しました。',
    },
    button: {
      new: '追加する',
      edit: '編集する',
      save: '保存する',
      delete: '削除する',
      cancel: 'キャンセル',
      create: '作成する',
      register: '登録する',
      update: '更新する',
      change: '変更する',
      restore: '復元する',
      suspend: '利用を停止する',
      upgrade: 'アップグレードする',
      unsubscribe: '退会する',
      editPlan: 'プランを変更する',
      invite: '招待する',
      close: '閉じる',
      confirm: '確認する',
      back: '戻る',
      unfollow: 'フォローを解除する',
      approve: '承認する',
    },
    dialog: {
      title: {
        new: '{target}追加',
        edit: '{target}編集',
      },
    },
    capacities: {
      'up_to_50': '50人まで',
      'up_to_100': '100人まで',
      'up_to_150': '150人まで',
      'up_to_200': '200人まで',
    },
    plans: {
      'trial': '無料トライアルプラン',
      'simple': 'シンプルプラン',
      'standard': 'スタンダードプラン',
    },
    prices: {
      trial: {
        'up_to_50': '0',
        'up_to_100': '0',
        'up_to_150': '0',
        'up_to_200': '0',
      },
      simple: {
        'up_to_50': '35000',
        'up_to_100': '60000',
        'up_to_150': '90000',
        'up_to_200': '100000',
      },
      standard: {
        'up_to_50': '52500',
        'up_to_100': '90000',
        'up_to_150': '135000',
        'up_to_200': '150000',
      },
    },
    results: {
      total: '{type}{name}の合計',
      count: '{type}{name}の数',
    },
    took: 'もらった',
    gave: 'おくった',
    thanks: '感謝',
    shakes: 'シェイク',
    tips: 'チップ',
    roles: {
      member: 'メンバー',
      leader: 'リーダー',
    },
    loginAt: 'ログイン日時',
    invitationAt: '招待日時',
    status: 'ステータス',
    invited: '招待中',
    accepted: '承認済',
    applied: '申請中',
    approved: '承認済',
    deactivated: '停止中',
    active: '利用中',
    all: '全て',
    createdAt: '作成日時',
  },
}
export default messages
