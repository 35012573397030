import _filter from 'lodash/filter'
import _uniq from 'lodash/uniq'
import _forEach from 'lodash/forEach'
import _isNil from 'lodash/isNil'
import _get from 'lodash/get'
import { Dictionary, Location } from 'vue-router/types/router'

export class AmazonesStorage {
  protected readonly storage: Storage
  protected readonly sessionKey = `_${location.hostname}`

  constructor(storage: Storage = sessionStorage) {
    this.storage = storage
  }

  get currentUrl(): string {
    return this.pull('currentPath') as string
  }

  set currentUrl(value: string) {
    this.store('currentPath', value)
  }

  get currentLocation(): Location {
    const [_path, ..._hashes] = this.currentUrl.split('#')
    const hash = _hashes.join('#')
    const [path, ..._params] = _path.split('?')
    const params: Dictionary<string> = {}
    _forEach(_uniq(_filter(_params.join('?').split('&'), (param) => param)), (param) => {
      const [key, value] = param.split('=')
      params[key] = value
    })
    return {
      path,
      hash,
      params,
    }
  }

  private get data(): JSONValue {
    const data = this.storage.getItem(this.sessionKey)
    if (_isNil(data)) {
      return {}
    }
    return JSON.parse(data)
  }

  store(key: string, value: string | number | object): void {
    const data = this.data
    data[key] = value
    this.storage.setItem(this.sessionKey, JSON.stringify(data))
  }

  pull(key: string): unknown {
    const value = _get(this.data, key, null)
    try {
      return JSON.parse(value)
    } catch {
      return value
    }
  }

  clear(key?: string): void {
    if (_isNil(key)) {
      this.storage.removeItem(this.sessionKey)
      return
    }
    const data = this.data
    delete data[key]
    this.storage.setItem(this.sessionKey, JSON.stringify(data))
  }

  async gotoCurrent(options: Partial<{ params: { [key: string]: string } }> = {}): Promise<void> {
    const currentLocation = this.currentLocation
    this.currentUrl = null
    const params: string[] = []
    _forEach(currentLocation.params, (value, key) => {
      params.push(`${key}=${value}`)
    })
    if (options && options.params) {
      _forEach(options.params, (value, key) => {
        params.push(`${key}=${value}`)
      })
    }
    let url = params.length > 0 ? `${currentLocation.path}?${params.join('&')}` : currentLocation.path
    url = currentLocation.hash ? `${url}#${currentLocation.hash}` : url
    await new Promise((resolve) => {
      window.setTimeout(resolve, 200)
    })
    location.replace(url)
  }
}

export default new AmazonesStorage(localStorage)
