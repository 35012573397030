import { FirebaseOptions, initializeApp } from 'firebase/app'
import { PluginObject } from 'vue'

let Vue
const VueFirebase: PluginObject<FirebaseOptions> = {
  async install(_Vue, options: FirebaseOptions) {
    if (typeof Vue !== 'undefined') {
      _Vue = Vue
    }
    const app = initializeApp(options)
    _Vue.prototype.$firebaseApp = app
  },
}
export default VueFirebase
