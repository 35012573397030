import VueI18n from 'vue-i18n'
import dateTimeFormats from '@/plugins/i18n/dateTimeFormats'
import Vue from 'vue'
import messages from '@/plugins/i18n/messages'

Vue.use(VueI18n)
const locale = (() => {
  if (window) {
    const language = (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language || 'ja'
    return language.split('-')[0]
  } else {
    return 'ja'
  }
})()
export default new VueI18n({
  locale,
  dateTimeFormats,
  messages,
})
