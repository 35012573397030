/* eslint camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
import { Fields } from '@vuex-orm/core'
import Model from '@/store/models/Model'

export default class GiftCategory extends Model {
  static entity = 'GiftCategory'
  static _endpoint = 'gift_categories'
  static primaryKey = ['gift_id', 'category_id']

  static fields(): Fields {
    return {
      gift_id: this.attr(null),
      category_id: this.attr(null),
    }
  }
}
