import { HistoryState } from './types'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const state: HistoryState = {
  startLength: 0,
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
