import { ActionTree } from 'vuex'
import { Maintenance, MaintenanceState } from './types'
import { RootState } from '@/store/modules/types'

const actions: ActionTree<MaintenanceState, RootState> = {
  start: async ({ commit }, value: Maintenance) => {
    commit('update', { value })
  },
  end: async ({ commit }) => {
    const payload: MaintenanceState = {
      value: undefined,
    }
    commit('update', payload)
  },
}
export default actions
