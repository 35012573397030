import { MaintenanceState } from './types'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const state: MaintenanceState = {
  value: null,
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
