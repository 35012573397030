/* eslint camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
import { Fields } from '@vuex-orm/core'
import Model from '@/store/models/Model'
import { User } from '@/store/models/Person'
import File from '@/store/models/File'

export default class Attachment extends Model {
  static entity = 'Attachment'
  static _endpoint = 'attachments'

  static fields(): Fields {
    return {
      id: this.attr(null),
      value: this.attr(null),
      user_id: this.attr(null),
      user: this.belongsTo(User, 'user_id'),
      file_id: this.attr(null),
      file: this.hasOne(File, 'file_id'),
      created_at: this.attr(null),
      updated_at: this.attr(null),
    }
  }
}
