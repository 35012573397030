import Vue from 'vue'
import Vuex from 'vuex'
import database from '@/store/database'
import VuexORM from '@vuex-orm/core'
import modules from '@/store/modules'

Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'
const plugins = [VuexORM.install(database)]
export default new Vuex.Store({
  strict: debug,
  plugins,
  modules,
})
