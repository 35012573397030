/* eslint camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
import Model from '@/store/models/Model'
import { Fields } from '@vuex-orm/core'
import { User } from '@/store/models/Person'

export default class AwardGiveCount extends Model {
  static entity = 'AwardGiveCount'
  static _endpoint = 'awards/gives'

  static fields(): Fields {
    return {
      id: this.number(null),
      name: this.attr(null),
      email: this.string(null),
      count: this.number(null),
      user: this.hasOne(User, 'id'),
    }
  }
}
