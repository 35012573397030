import Gift from '@/store/models/Gift'
import Tweet from '@/store/models/Tweet'
import Comment from '@/store/models/Comment'
import * as types from '@/store/models/types'
import Thank from '@/store/models/Thank'

export const timelinableType: {
  [key: string]: types.TimelinableModel
} = {
  Tweet,
  Gift,
}
export const reactableType: {
  [key: string]: types.ReactableModel
} = {
  Tweet,
  Gift,
  Comment,
  Thank,
}
export const commentableType: {
  [key: string]: types.CommentableModel
} = {
  Tweet,
  Gift,
}
export const activatableType: {
  [key: string]: types.ActivatableModel
} = {
  Thank,
}
export const nullDateForOrderBy = 'ZZZZ-12-31T23:59:59.999'
