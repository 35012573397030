import { ActionTree } from 'vuex'
import { ScreenError, ScreenState } from './types'
import { RootState } from '@/store/modules/types'

const actions: ActionTree<ScreenState, RootState> = {
  showError: async ({ commit }, error: ScreenError) => {
    commit('update', { error })
  },
  clear: async ({ commit }) => {
    const payload: ScreenState = {
      error: undefined,
    }
    commit('update', payload)
  },
}
export default actions
