/* eslint camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
import { Fields, Record } from '@vuex-orm/core'
import Feeling from '@/store/models/Feeling'
import _map from 'lodash/map'
import Category from '@/store/models/Category'
import GiftCategory from '@/store/models/GiftCategory'
import _isNil from 'lodash/isNil'
import Reaction from '@/store/models/Reaction'
import Comment from '@/store/models/Comment'
import _get from 'lodash/get'
import { User } from '@/store/models/Person'
import File from '@/store/models/File'
import Model from '@/store/models/Model'
import { getDisplayName } from '@/utils/user'

export default class Gift extends Model {
  static entity = 'Gift'
  static _endpoint = 'gifts'

  get recipientName(): string {
    return getDisplayName(_get(this, 'recipient'))
  }

  get senderName(): string {
    return getDisplayName(_get(this, 'sender'))
  }

  static fields(): Fields {
    return {
      id: this.number(null),
      point: this.number(null),
      message: this.string(null),
      edited: this.attr(false),
      accepted_at: this.attr(null),
      recipient_id: this.number(null),
      recipient: this.belongsTo(User, 'recipient_id'),
      recipient_feeling_id: this.attr(null),
      recipient_feeling: this.belongsTo(Feeling, 'recipient_feeling_id'),
      sender_id: this.number(null),
      sender: this.belongsTo(User, 'sender_id'),
      sender_feeling_id: this.attr(null),
      sender_feeling: this.belongsTo(Feeling, 'sender_feeling_id'),
      categories: this.belongsToMany(Category, GiftCategory, 'gift_id', 'category_id'),
      reactions: this.morphMany(Reaction, 'reactable_id', 'reactable_type'),
      comments: this.morphMany(Comment, 'commentable_id', 'commentable_type'),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      file_ids: this.attr([]),
      files: this.hasManyBy(File, 'file_ids'),
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static create({ data }): Promise<any> {
    return this.dispatch('create', { data: this.doProc(data) })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static insert({ data }): Promise<any> {
    return this.dispatch('insert', { data: this.doProc(data) })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static update({ data }): Promise<any> {
    return this.dispatch('update', { data: this.doProc(data) })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static insertOrUpdate({ data }): Promise<any> {
    return this.dispatch('insertOrUpdate', { data: this.doProc(data) })
  }

  static doProc(data: Record | Record[]): Record | Record[] {
    if (_isNil(data)) return data
    return _map(Array.isArray(data) ? data : [data], (gift) => Gift.format(gift))
  }

  static format(gift: Record): Record {
    const current = Gift.getters('find')(gift.id)
    if (gift.recipient.feeling) {
      gift['recipient_feeling'] = gift.recipient.feeling
      delete gift.recipient.feeling
    }
    if (gift.sender) {
      if (gift.sender.feeling) {
        gift['sender_feeling'] = gift.sender.feeling
        delete gift.sender.feeling
      }
    } else {
      // HACK: 0は利用されているので-1で設定する
      gift['sender_id'] = -1
    }
    if (current && current.accepted_at) {
      gift.accepted_at = current.accepted_at
    }
    gift.reactions = gift.reactions.map((reaction) => Reaction.format(reaction))
    gift.comments = gift.comments.map((comment) => Comment.format(comment))
    return gift
  }
}
