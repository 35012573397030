import history from './history'
import screen from './screen'
import imagePreview from './imagePreview'
import maintenance from './maintenance'
import noticeDialog from './noticeDialog'

export default {
  history,
  screen,
  imagePreview,
  maintenance,
  noticeDialog,
}
