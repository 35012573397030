/* eslint camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
import Model from '@/store/models/Model'
import { Fields, Record } from '@vuex-orm/core'
import _map from 'lodash/map'
import _isNil from 'lodash/isNil'
import { activatableType } from '@/store/models/const'

export default class Activity extends Model {
  static entity = 'Activity'
  static _endpoint = 'activities'

  static fields(): Fields {
    return {
      id: this.attr(null),
      activatable_id: this.attr(null),
      activatable_type: this.attr(null),
      activatable: this.morphTo('activatable_id', 'activatable_type'),
      created_at: this.attr(null),
      updated_at: this.attr(null),
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static create({ data }): Promise<any> {
    return this.dispatch('create', { data: this.doProc(data) })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static insert({ data }): Promise<any> {
    return this.dispatch('insert', { data: this.doProc(data) })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static update({ data }): Promise<any> {
    return this.dispatch('update', { data: this.doProc(data) })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static insertOrUpdate({ data }): Promise<any> {
    return this.dispatch('insertOrUpdate', { data: this.doProc(data) })
  }

  static doProc(data: Record | Record[]): Record | Record[] {
    if (_isNil(data)) return data
    return _map(Array.isArray(data) ? data : [data], (activity) => this.format(activity))
  }

  static format(activity: Record): Record {
    const model = activatableType[activity.activatable_type]
    const { activatable } = activity
    activatable['created_at'] = activity.created_at
    activatable['updated_at'] = activity.updated_at
    activity.activatable = model.format(activatable)
    activity['activatable_id'] = activity.activatable.id
    return activity
  }
}
