import { ActionTree } from 'vuex'
import { PreviewState } from './types'
import { RootState } from '@/store/modules/types'
import { Record } from '@vuex-orm/core'
import _cloneDeep from 'lodash/cloneDeep'

const actions: ActionTree<PreviewState, RootState> = {
  show: async ({ commit }, { file, onDelete }: { file: Record; onDelete: () => void }) => {
    commit('update', { value: true, file: _cloneDeep(file), delete: onDelete })
  },
  hide: async ({ commit }) => {
    commit('update', { value: false, file: null, delete: null })
  },
  delete: async ({ commit }) => {
    commit('delete')
  },
}
export default actions
