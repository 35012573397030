/* eslint camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
import { Fields, Record } from '@vuex-orm/core'
import { emojify } from '@/utils/emoji'
import _map from 'lodash/map'
import _groupBy from 'lodash/groupBy'
import _values from 'lodash/values'
import _mapValues from 'lodash/mapValues'
import _cloneDeep from 'lodash/cloneDeep'
import _isNil from 'lodash/isNil'
import { Resident, User } from '@/store/models/Person'
import Model from '@/store/models/Model'

export default class Reaction extends Model {
  static entity = 'Reaction'
  static _endpoint = 'reactions'

  get emoji(): string {
    return emojify(this['value'])
  }

  get who(): Record {
    return this['user'] || this['resident']
  }

  static fields(): Fields {
    return {
      id: this.attr(null),
      value: this.attr(null),
      reactable_id: this.attr(null),
      reactable_type: this.attr(null),
      user_id: this.attr(null),
      user: this.belongsTo(User, 'user_id'),
      resident_id: this.number(null),
      resident: this.belongsTo(Resident, 'resident_id'),
      created_at: this.attr(null),
      updated_at: this.attr(null),
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static create({ data }): Promise<any> {
    return this.dispatch('create', { data: this.doProc(data) })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static insert({ data }): Promise<any> {
    return this.dispatch('insert', { data: this.doProc(data) })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static update({ data }): Promise<any> {
    return this.dispatch('update', { data: this.doProc(data) })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static insertOrUpdate({ data }): Promise<any> {
    return this.dispatch('insertOrUpdate', { data: this.doProc(data) })
  }

  static doProc(data: Record | Record[]): Record | Record[] {
    if (_isNil(data)) return data
    return _map(Array.isArray(data) ? data : [data], (reaction) => this.format(reaction))
  }

  static format(reaction: Record): Record {
    return reaction
  }

  static uniq(reactions: Record[]): Record[] {
    const group = _mapValues(_groupBy(reactions, 'value'), (reaction) => {
      const { value, emoji } = _cloneDeep(reaction[0])
      const users = _map(reaction, 'user').filter((val) => !_isNil(val))
      const residents = _map(reaction, 'resident').filter((val) => !_isNil(val))
      return {
        value,
        emoji,
        users: [...users, ...residents],
      }
    })
    return _values(group)
  }
}
