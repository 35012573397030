import http from '@/utils/http'
import { PluginObject } from 'vue'
import { Configuration } from './interfaces'

let Vue
const VueHttp: PluginObject<Configuration> = {
  install(_Vue, options?: Configuration) {
    if (Vue) {
      _Vue = Vue
    }
    const _options = { http, ...options }
    _Vue.prototype.$http = _options.http
  },
}
export default VueHttp
