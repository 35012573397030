/* eslint camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
import Model from '@/store/models/Model'
import { Fields, Item } from '@vuex-orm/core'
import { User } from '@/store/models/Person'

export default class Exchange extends Model {
  static entity = 'Exchange'
  static _endpoint = 'exchanges'

  id!: number
  point!: number
  memo!: string
  created_at: string
  updated_at: string
  deleted_at: string
  online: boolean
  user: Item<User>

  static fields(): Fields {
    return {
      id: this.number(null),
      point: this.number(null),
      memo: this.string(null),
      user_id: this.attr(null),
      user: this.belongsTo(User, 'user_id'),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      deleted_at: this.attr(null),
    }
  }
}
