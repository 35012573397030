import Vue from 'vue'
import Vuetify from 'vuetify'
import NotificationOptions from './NotificationOptions'
import VueNotification from './MyNotification.vue'
import { mdiAlert, mdiCheckCircle, mdiExclamation, mdiInformation } from '@mdi/js'
import vuetify from '@/plugins/vuetify'

Vue.use(Vuetify)

export class NotificationIcon {
  private readonly _icon: string

  constructor(type: 'error' | 'warning' | 'success' | 'info' | string) {
    this._icon = (() => {
      switch (type) {
        case 'error':
          return mdiCheckCircle
        case 'warning':
          return mdiExclamation
        case 'success':
          return mdiAlert
        case 'info':
          return mdiInformation
      }
      return null
    })()
  }

  get value(): string {
    return this._icon
  }
}

class Notification {
  defaultOptions: NotificationOptions = {
    text: null,
    icon: null,
    timeout: 3000,
    dismissible: true,
    y: 'top',
    x: 'right',
    mode: null,
    color: null,
    button: null,
  }
  private _instance

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private get instance(): any {
    if (!this._instance) {
      this._instance = new VueNotification({ vuetify })
      document.body.appendChild(this._instance.$mount().$el)
    }
    return this._instance
  }

  show(options?: NotificationOptions): Promise<string> {
    return new Promise((resolve) => {
      const instance = this.instance
      instance.$on('close', (action) => {
        resolve(action)
      })
      if (instance.active) {
        instance.close()
        instance.$nextTick(() => instance.show(options))
        return
      }
      instance.show({ ...this.defaultOptions, ...options })
    })
  }

  close(): void {
    this.instance.close()
  }

  success(text: string, options?: NotificationOptions): Promise<string> {
    return this.show({ color: 'success', icon: new NotificationIcon('info').value, text, ...options })
  }

  info(text: string, options?: NotificationOptions): Promise<string> {
    return this.show({ color: 'info', icon: new NotificationIcon('info').value, text, ...options })
  }

  error(text: string, options?: NotificationOptions): Promise<string> {
    return this.show({ color: 'error', icon: new NotificationIcon('error').value, text, ...options })
  }

  warning(text: string, options?: NotificationOptions): Promise<string> {
    return this.show({ color: 'warning', icon: new NotificationIcon('warning').value, text, ...options })
  }
}

export default new Notification()
