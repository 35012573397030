/* eslint-disable @typescript-eslint/no-explicit-any */
import Axios, { AxiosError, AxiosPromise, AxiosRequestConfig, CancelTokenSource } from 'axios'
import _get from 'lodash/get'
import config from '@/config'
import notification from '@/utils/notification'
import _forEach from 'lodash/forEach'
import _isNil from 'lodash/isNil'
import HttpError from '@/utils/HttpError'
import store from '@/store'

const http = Axios.create(config.api.axios)

export function enhanceHttpError(error: AxiosError | HttpError): HttpError {
  const httpError: HttpError = error as unknown as HttpError
  _forEach(error.response, (val, key) => {
    httpError[key] = val
  })
  _forEach(httpError, (val, key) => {
    httpError[key] = val
  })
  httpError.name = error.name
  httpError.message = error.message
  httpError.stack = error.stack
  return httpError
}

http.interceptors.request.use((res) => {
  const maintenance = store.state.maintenance.value
  if (!_isNil(maintenance)) return Promise.reject(maintenance)
  return res
})
http.interceptors.response.use(
  (res) => res,
  (err) => {
    const maintenance = store.state.maintenance.value
    if (!_isNil(maintenance)) return Promise.reject(maintenance)
    if (_get(err.response, 'status') === 500) {
      notification.close()
      const message = _get(err.response, 'data.error', _get(err, 'message'))
      notification.error(message)
    }
    if (_get(err.response, 'status') === 503) {
      const maintenance: {
        display: 'always' | 'once'
        message: string
        url: string
      } = err.response.data
      store.dispatch('maintenance/start', maintenance)
      alert(maintenance.message)
      if (maintenance.display === 'always') location.href = '/'
      if (maintenance.display === 'once') store.dispatch('maintenance/end')
    }
    return Promise.reject(enhanceHttpError(err))
  },
)
type RansackQuery = {
  [key: string]: string | string[] | number | number[] | boolean
}
type RansackSort = {
  'q[s]': string[]
}
export type SearchSort = {
  name: string
  order?: 'desc' | 'asc'
}[]
export type SearchParams = {
  /** @deprecated */
  condition: {
    [key: string]: string | string[] | number | number[] | boolean
  }
  page: number
  limit: number
  sort: SearchSort
}
export type RansackParams = RansackQuery &
  RansackSort & {
  page: number
  limit: number
}

export class SearchCondition {
  constructor(private searchParams: Partial<SearchParams>) {
  }

  static toRansackParams(searchParams: Partial<SearchParams>): Partial<RansackParams> {
    return new SearchCondition(searchParams).toRansackParams()
  }

  toRansackParams(): Partial<RansackParams> {
    const { condition, page, limit, sort } = this.searchParams
    const params: Partial<RansackParams> = {}
    if (!_isNil(page)) {
      params['page'] = page
    }
    if (!_isNil(limit)) {
      params['limit'] = limit
    }
    if (!_isNil(sort) && sort.length > 0) {
      if (_isNil(params['q[s]'])) params['q[s]'] = []
      sort.forEach(({ name, order }) => {
        params['q[s]'].push(`${name} ${order || 'asc'}`)
      })
    }
    _forEach(condition, (val, key) => {
      params[`q[${key}]`] = val
    })
    return params
  }
}

interface HttpRequestConfig extends AxiosRequestConfig {
  requestId?: string
}

class HttpRequest {
  public sources: {
    [requestId: string]: CancelTokenSource
  } = {}

  request(config: HttpRequestConfig): AxiosPromise {
    const requestId = this.createRequestId(config)
    this.sources[requestId] = Axios.CancelToken.source()
    config.cancelToken = this.sources[requestId].token
    return http.request(config)
  }

  get(url: string, config?: HttpRequestConfig): AxiosPromise {
    const _config: HttpRequestConfig = {
      ...config,
      method: 'get',
      url,
    }
    return this.request(_config)
  }

  delete(url: string, config?: HttpRequestConfig): AxiosPromise {
    const _config: HttpRequestConfig = {
      ...config,
      method: 'delete',
      url,
    }
    return this.request(_config)
  }

  head(url: string, config?: HttpRequestConfig): AxiosPromise {
    const _config: HttpRequestConfig = {
      ...config,
      method: 'head',
      url,
    }
    return this.request(_config)
  }

  post(url: string, data?: any, config?: HttpRequestConfig): AxiosPromise {
    const _config: HttpRequestConfig = {
      ...config,
      method: 'post',
      url,
      data,
    }
    return this.request(_config)
  }

  put(url: string, data?: any, config?: HttpRequestConfig): AxiosPromise {
    const _config: HttpRequestConfig = {
      ...config,
      method: 'put',
      url,
      data,
    }
    return this.request(_config)
  }

  patch(url: string, data?: any, config?: HttpRequestConfig): AxiosPromise {
    const _config: HttpRequestConfig = {
      ...config,
      method: 'patch',
      url,
      data,
    }
    return this.request(_config)
  }

  search(url: string, searchParams: Partial<SearchParams>): AxiosPromise {
    const params = new SearchCondition(searchParams).toRansackParams()
    return this.get(url, {
      params,
    })
  }

  cancelAll(): void {
    _forEach(this.sources, (source) => {
      source.calcel()
    })
  }

  cancel(config: HttpRequestConfig): void {
    const requestId = this.createRequestId(config)
    this.sources[requestId].cancel()
  }

  private createRequestId(config: HttpRequestConfig): string {
    const requestIds = []
    requestIds.push(config.method)
    requestIds.push(config.url)
    if (!_isNil(config.requestId)) {
      requestIds.push(config.requestId)
    }
    if (!_isNil(config.data)) {
      requestIds.push(JSON.stringify(config.data))
    }
    if (!_isNil(config.params)) {
      requestIds.push(JSON.stringify(config.params))
    }
    return requestIds.join(':')
  }
}

export default new HttpRequest()
