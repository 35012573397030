/* eslint camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
import { User } from '@/store/models/Person'
import Tenant from '@/store/models/Tenant'
import _get from 'lodash/get'
import dataLayer from '@/utils/dataLayer'
import { Fields, Item, Record } from '@vuex-orm/core'
import http, { SearchCondition } from '@/utils/http'
import moment from 'moment'
import _isNil from 'lodash/isNil'
import * as Sentry from '@sentry/browser'
import Gift from '@/store/models/Gift'
import _find from 'lodash/find'

export interface LoadOptions {
  useCache: boolean
}

export default class Me extends User {
  static entity = 'Me'
  static _endpoint = 'me'
  static baseEntity = User.endpoint()
  tenant_id!: number
  tenant!: Item<Tenant>

  get communityHost(): string {
    const tenant = _get(this, 'tenant') || Tenant.find(_get(this, 'tenant_id'))
    const pattern = new RegExp(`^${_get(tenant, 'name')}\\.`)
    const host = 'ch.' + location.host.replace(pattern, '')
    return `${location.protocol}//${host}`
  }

  static fields(): Fields {
    return {
      ...super.fields(),
      tenant_id: this.attr(null),
      tenant: this.belongsTo(Tenant, 'tenant_id'),
    }
  }

  static async load(options: Partial<LoadOptions> = {}): Promise<void> {
    const useCache = !_isNil(options['useCache']) ? options['useCache'] : true
    if (this.loading) {
      await this.waitLoading()
    }
    if (useCache && this.cachedTimestamp > moment().add(-1, 'minute').unix()) {
      return
    }
    this.loading = true
    const requests = [http.get('me')]
    requests.push(http.get('us'))
    const responses = await Promise.all(requests)
    const user = responses[0].data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = {
      ...user,
    }
    let card: object = undefined
    if (_get(user, 'admin')) {
      const payment = await http.get('our/payment')
      let cards = _get(payment.data, 'cards')
      cards = _get(cards, 'data')
      card = _get(cards, '0')
    }
    data.tenant = {
      ...responses[1].data,
      card,
    }
    await this.insertOrUpdate({ data })
    Gift.fetch({
      url: ['users', user.id, 'takes'].join('/'),
      params: new SearchCondition({ condition: { accepted_at_null: true } }).toRansackParams(),
      useCache: false,
    })
    this.loading = false
    this.cachedTimestamp = moment().unix()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static create(payload): Promise<any> {
    return this.dispatch('create', payload)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static insert(payload): Promise<any> {
    return this.dispatch('insert', payload)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static update(payload): Promise<any> {
    return this.dispatch('update', payload)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static insertOrUpdate(payload): Promise<any> {
    return this.dispatch('insertOrUpdate', payload)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static dispatch(method: string, payload?: any): Promise<any> {
    User.dispatch(method, payload)
    const result = super.dispatch(method, payload)
    const currentUser: Record = this.query().with('tenant').first()
    const email = _get(currentUser, 'email')
    dataLayer.push({
      userid: email,
      userId: email,
      tenant: _get(currentUser.tenant, 'name'),
    })
    Sentry.configureScope((scope) => {
      scope.setUser({ email })
    })
    return result
  }

  following(userId: number): boolean {
    return !_isNil(_find(this.followings, (item) => item.id === userId))
  }
}
