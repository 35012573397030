/* eslint camelcase: off */
import { Fields } from '@vuex-orm/core'
import Model from '@/store/models/Model'

export type ExchangeMode = 'stack' | 'drawable'

export default class Tenant extends Model {
  static entity = 'Tenant'
  static _endpoint = 'tenants'
  id!: number
  name!: string
  display_name: string
  points_per_unit: number
  point_expires_after: number
  distribution_cycle: 'weekly' | 'monthly'
  distribution_days: number[]
  created_at: string
  updated_at: string
  payment: string
  card: string
  plan: 'trial' | 'simple' | 'standard'
  capacity: 'more' | 'up_to_50' | 'up_to_100' | 'up_to_150' | 'up_to_200'
  overstaying: boolean
  integrations: {
    [key in 'slack']: boolean
  }
  exchange_mode: ExchangeMode

  static fields(): Fields {
    return {
      id: this.attr(null),
      name: this.attr(null),
      display_name: this.attr(null),
      points_per_unit: this.attr(500),
      point_expires_after: this.attr(1),
      distribution_cycle: this.attr('weekly'),
      distribution_days: this.attr([]),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      payment: this.attr(null),
      card: this.attr(null),
      plan: this.attr(null),
      capacity: this.attr(null),
      overstaying: this.attr(null),
      integrations: this.attr(null),
      exchange_mode: this.attr('stack'),
    }
  }
}
