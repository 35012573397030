import { PluginObject } from 'vue'
import { Configuration } from './interfaces'
import 'moment/locale/ja'
import moment from 'moment'

let Vue
const VueMoment: PluginObject<Configuration> = {
  install(_Vue) {
    if (typeof Vue !== 'undefined') {
      _Vue = Vue
    }
    _Vue.filter('moment', this.moment)
    _Vue.prototype.$moment = moment
  },
  moment(method, ...args) {
    return moment[method](args)
  },
}
export default VueMoment
