import { PluginObject } from 'vue'
import { Configuration, Omise } from './interfaces'

let Vue
const VueOmise: PluginObject<Configuration> = {
  install(_Vue, options: Partial<Configuration> = {}) {
    if (typeof Vue !== 'undefined') {
      _Vue = Vue
    }
    // HACK: htmlでhttps://cdn2.omise.co/omise.jsが読み込まれている事が前提
    const omise: Omise = window['Omise']
    omise.setPublicKey(options.publicKey)
    _Vue.prototype.$omise = omise
  },
}
export default VueOmise
