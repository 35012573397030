/* eslint camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
import Model from '@/store/models/Model'
import { Fields } from '@vuex-orm/core'

export default class Community extends Model {
  static entity = 'Community'
  static _endpoint = 'communities'

  static fields(): Fields {
    return {
      id: this.attr(null),
      name: this.attr(null),
      channel: this.attr(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
    }
  }
}
