import { MutationTree } from 'vuex'
import { ScreenState } from './types'

const mutations: MutationTree<ScreenState> = {
  set: (state: ScreenState, payload: ScreenState) => {
    const keys = Object.keys(state)
    keys.forEach((key) => (state[key] = payload[key]))
    Object.keys(payload)
      .filter((key) => !keys.includes(key))
      .forEach((key) => (state[key] = payload[key]))
  },
  update: (state: ScreenState, payload: ScreenState) => {
    Object.keys(payload).forEach((key) => (state[key] = payload[key]))
  },
}
export default mutations
