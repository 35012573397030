/* eslint camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
import Model from '@/store/models/Model'
import { Fields } from '@vuex-orm/core'

export default class Notice extends Model {
  static entity = 'Notice'
  static _endpoint = 'notices'

  static fields(): Fields {
    return {
      id: this.number(null),
      subject: this.attr(null),
      body: this.attr(null),
      to: this.attr(null),
      begin_on: this.attr(null),
      end_on: this.attr(null),
      pinned: this.boolean(null),
      published: this.boolean(null),
      read: this.boolean(null),
    }
  }
}
