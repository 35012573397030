import { DateTimeFormats } from 'vue-i18n'

const dateTimeFormats: DateTimeFormats = {
  ja: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
}
export default dateTimeFormats
