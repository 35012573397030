import { NoticeDialogState } from './types'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const state: NoticeDialogState = {
  value: false,
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
