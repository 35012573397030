import { Configuration } from 'rollbar'
import { PluginObject } from 'vue'

let Vue
const VueRollbar: PluginObject<Configuration> = {
  async install(_Vue, options: Partial<Configuration> = {}) {
    if (typeof Vue !== 'undefined') {
      _Vue = Vue
    }
    // RollbarをimportするとサーバーサイドのAPIになってしまうので出力されたものを読み込む
    await import('rollbar/dist/rollbar.js')
    const rollbar = new window['rollbar'](options)
    _Vue['rollbar'] = rollbar
    _Vue.prototype.$rollbar = rollbar
  },
}
export default VueRollbar
