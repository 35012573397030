import { PreviewState } from './types'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const state: PreviewState = {
  value: false,
  file: null,
  delete: null,
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
